const useDownloadFile = (file: BlobPart | string, fileName: string) => {
  const downloadUrl = file instanceof File ? window.URL.createObjectURL(new Blob([file])) : file;
  const link = document.createElement('a');
  link.href = downloadUrl as string;
  link.setAttribute('download', fileName);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default useDownloadFile;
