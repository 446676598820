import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Empty } from '@components';
import { SendIcon, UserIcon } from '@assets/svg';
import { format } from 'date-fns';
import { DEFAULT_USER_DATE_TIME_FORMAT } from '@common/constants';
import { Mention, MentionsInput } from 'react-mentions';
import { IComment, IFocalPoint } from '@common/interfaces';
import css from './styles.module.scss';

interface IChat {
  comments: IComment[];
  maxHeight?: string;
  focalPoints?: Array<IFocalPoint>;
  sendComment: (comment: string) => void;
}

interface Message {
  text: string;
}

const Message: FC<Message> = ({ text }) => {
  const regex = /@\[([^[]+)\]\(([^)]+)\)/g;
  const parts = text.split(regex);

  return (
    <div className={css.text}>
      {parts.map((part, index) => {
        // If the part of the string matches the regular expression, create bold text
        if (index % 3 === 1) {
          return <b key={index}>{part}</b>;
        } else if (index % 3 === 2) {
          // If the part of the string contains an identifier, skip it
          return null;
        } else {
          // If the part is simple text just return it
          return part;
        }
      })}
    </div>
  );
};

const Chat: FC<IChat> = ({ comments, focalPoints = [], sendComment }) => {
  const [comment, setComment] = useState('');

  const mentionList = focalPoints.map(({ first_name, last_name, uuid }) => ({
    display: `${first_name} ${last_name}`,
    id: uuid,
  }));

  const handleSend = () => {
    sendComment(comment);
    setComment('');
  };

  const scrollableContentRef = useRef(null);
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    if (scrollableContentRef.current && !firstRender) {
      (scrollableContentRef.current as HTMLFormElement).scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    } else {
      setFirstRender(false);
    }
  }, [comments]);

  const blockRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (blockRef.current) setHeight(blockRef.current.getBoundingClientRect().height);
  }, []);

  return (
    <div className={css.chat} ref={blockRef}>
      <div className={css.content} style={{ height }}>
        {comments.length === 0 ? (
          <Empty text='No updates available at the moment' />
        ) : (
          <div className={css.comments}>
            {comments.map((comment, idx) => (
              <div className={css.comment} key={comment.uuid}>
                <div className={css.commentSide}>
                  <div className={css.icon}>
                    <UserIcon />
                  </div>
                  {idx !== comments.length - 1 ? <div className={css.dots} /> : null}
                </div>
                <div className={css.commentMain}>
                  <div className={css.info}>
                    <div
                      className={css.author}
                    >{`${comment.created_by.first_name} ${comment.created_by.last_name}`}</div>
                    <div className={css.date}>{format(new Date(comment.created), DEFAULT_USER_DATE_TIME_FORMAT)}</div>
                  </div>
                  <Message text={comment.body} />
                </div>
              </div>
            ))}
            <div ref={scrollableContentRef} className={css.anchor} />
          </div>
        )}
      </div>
      <div className={css.action}>
        <MentionsInput
          a11ySuggestionsListLabel='Suggestions'
          className='react-mention'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder='Write a comment...'
        >
          <Mention className={css.tagged} trigger='@' data={mentionList} />
        </MentionsInput>
        <Button iconR={<SendIcon />} disabled={comment.length < 2} className={css.button} onClick={handleSend} />
      </div>
    </div>
  );
};

export default Chat;
