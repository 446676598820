import { TAuthConfig } from '@unbooking/ui-auth';

const scope = 'email openid profile';

const authConfig: TAuthConfig = {
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
  authorizationEndpoint: process.env.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT ?? '',
  autoAuthorize: false,
  unbhPermissionsEndpoint: '',
  tokenEndpoint: process.env.REACT_APP_AUTH_TOKEN_ENDPOINT ?? '',
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? '',
  logoutEndpoint: process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT ?? '',
  logoutUri: process.env.REACT_APP_AUTH_LOGOUT_URI ?? '',
  refreshTokenErrorCodes: [Number(process.env.REACT_APP_AZURE_REFRESH_TOKEN_ERROR_CODE)],
  scope,
  configurations: {
    b2c: {
      authorizationEndpoint: process.env.REACT_APP_B2C_AUTHORIZATION_ENDPOINT || '',
      tokenEndpoint: process.env.REACT_APP_B2C_TOKEN_ENDPOINT ?? '',
      clientId: process.env.REACT_APP_B2C_CLIENT_ID || '',
      logoutEndpoint: process.env.REACT_APP_B2C_LOGOUT_ENDPOINT ?? '',
      logoutUri: process.env.REACT_APP_B2C_LOGOUT_URI || '',
      scope: `${scope} offline_access`,
    },
  },
};

export default authConfig;
