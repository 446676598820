import { MAX_ASSET_YEAR, MIN_ASSET_YEAR } from '@common/constants';
import { IDropdownOption } from '@common/interfaces';
import { IAssetDropdownOption } from '@common/utils/mapPlateNumbers';
import * as Yup from 'yup';

export interface DetailsStepValues {
  requestor_email: string;
  requestor_name: string;
  requestor_phone_number: string;
  requestor_surname: string;
  requesting_agency: string;
  manager_email: string;
  manager_name: string;
  manager_surname: string;
  collector_email?: string;
  collector_name?: string;
  collector_phone_number?: string;
  collector_surname?: string;
}

export interface AssetStepValues {
  current_odometer_value: string;
  has_spare_parts: IDropdownOption;
  is_quotation_required: IDropdownOption;
  make: IDropdownOption;
  model: IDropdownOption;
  odometer_metric: IDropdownOption;
  plate_number: IAssetDropdownOption;
  type_of_asset: IDropdownOption;
  job_reason: IDropdownOption;
  year_of_asset: string;
  known_issues: {
    type_of_repair: string;
    text: string;
  }[];
}

export interface ReviewStepValues {
  remarks: string;
}

export interface FormValues {
  details: DetailsStepValues;
  asset: AssetStepValues;
  review?: ReviewStepValues;
}

export interface FormTempValues {
  details?: Partial<DetailsStepValues>;
  asset?: Partial<AssetStepValues>;
  review?: Partial<ReviewStepValues>;
}

export type IYupSchema = Yup.SchemaOf<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}>;

export const MAX_REMARKS_LENGTH = 255;
export const MAX_ODOMETER_COUNT = 2147483647;

export const STEPS = {
  DETAILS: 'details',
  ASSET: 'asset',
  REVIEW: 'review',
};

const YupIssueType = () =>
  Yup.object().shape({
    text: Yup.string().required('Text is required'),
    type_of_repair: Yup.string().required('Type of repair is required'),
  });

const YupDropdownType = () => Yup.object().required('Required');

const YupYearType = () =>
  Yup.number()
    .positive('Must be a positive number')
    .max(MAX_ASSET_YEAR, `Year must be less than or equal to ${MAX_ASSET_YEAR}`)
    .min(MIN_ASSET_YEAR, `Year must be more than ${MIN_ASSET_YEAR}`)
    .nullable();

const YupPhoneType = () =>
  Yup.string().matches(/^(?=(?:[^0-9]*[0-9]){0,15}[^0-9]*$)[\d+\s]+$/, 'Incorrect phone format');

export const yupSchemas = {
  [STEPS.DETAILS]: Yup.object().shape({
    [STEPS.DETAILS]: Yup.object().shape({
      // requestor_phone_number: Yup.string().phone(undefined, undefined, 'Incorrect phone format').required('Required'),
      requestor_phone_number: YupPhoneType().required('Required'),
      manager_email: Yup.string().email('Must be a valid email').required('Required'),
      manager_name: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, 'Name must contain only letters and spaces')
        .required('Required'),
      manager_surname: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, 'Name must contain only letters and spaces')
        .required('Required'),
      collector_email: Yup.string().email('Must be a valid email'),
      collector_name: Yup.string().matches(/^[a-zA-Z\s]+$/, 'Name must contain only letters and spaces'),
      collector_phone_number: YupPhoneType(),
      collector_surname: Yup.string().matches(/^[a-zA-Z\s]+$/, 'Name must contain only letters and spaces'),
    }),
  }),
  [STEPS.ASSET]: Yup.object().shape({
    [STEPS.ASSET]: Yup.object().shape({
      current_odometer_value: Yup.string().required('Required'),
      known_issues: Yup.array()
        .of(YupIssueType())
        .min(1, 'At least one known issue is required')
        .required('At least one known issue is required'),
      odometer_metric: YupDropdownType(),
      job_reason: YupDropdownType(),
      make: YupDropdownType(),
      model: YupDropdownType(),
      plate_number: YupDropdownType(),
      has_spare_parts: YupDropdownType(),
      is_quotation_required: YupDropdownType(),
      type_of_asset: YupDropdownType(),
      year_of_asset: YupYearType(),
    }),
  }),
  [STEPS.REVIEW]: Yup.object().shape({
    [STEPS.REVIEW]: Yup.object().shape({
      remarks: Yup.string().max(MAX_REMARKS_LENGTH, `Remarks must be at most ${MAX_REMARKS_LENGTH} characters`),
    }),
  }),
};
